import { SvgIcon } from "@mui/material";

function Spark(props) {
    let colours = props.colours || ["#E25C26"];
    return (
        <SvgIcon {...props} viewBox="0 -10 1000 1100">
            <svg fill={colours[0]}>
                <path
                    class="st0"
                    d="M738.7,674.7l-4.1-8.8L644.4,495c-3.1-5.7-2.7-9.1,1.4-14l142.6-167.5c1.7-2,3.2-4.1,3.8-7.5l-41.7,10.9
                        l-173,46c-5.4,1.4-7.8-0.1-10.5-4.6l-98.2-163.7c-1.7-2.8-3.5-5.4-7.2-8l-7.9,43.7l-27.4,154.3l-2.8,16.7c-0.5,5.4-3.2,7.3-8,8.8
                        l-203.6,64.4c-3,1-5.9,2.2-9.1,5.1L370.4,546l-4.9,3.9l-104.2,67.4c-3.5,2.7-8.2,3.1-12.1,1l-124.6-55.5
                        c-18.6-8.2-35.4-19.4-48.5-35.3c-29.5-36.1-23.7-77.2,15.6-102.2c12.9-8.2,27.5-14.4,42.1-19.1l200-63.5c5.4-1.7,8.2-4.2,9.2-10.3
                        l27.2-154.3c5-27.5,7.6-55.8,21-80.9c5.1-9.5,11.3-19.2,18.6-27.3c26.5-29.4,63.5-30.5,91.5-2.5c9.5,9.5,17.7,20.6,24.6,32
                        l91.3,151.7c3.6,6,6.8,7.2,13.4,5.4L854.2,197c15.4-4.1,30.9-5.5,46.7-2.7c34.3,6.4,49.3,32.7,37.7,65.7
                        c-5.3,15-14.4,27.9-24.5,39.9l-156,183.4c-4.3,5-4.4,8.6-1.4,14.2l92.9,176.2c7.4,14.1,13.1,28.6,13.2,44.8
                        c0.4,36.7-26.5,66.8-63,72.1c-20.4,3-39.4-1.4-58.6-7.3L601,740.6c-4.3-1.3-6-3-6.8-7.7l-16.9-98.6c-0.2-0.9,0.1-1.9,0.2-3.9
                        l161,44.4"
                />
                <path
                    class="st1"
                    d="M370.2,546l29.7,12.9l-33,262.9l180.4-199.9l30.4,8.5l37.5,113.9l-48.7-17.2l-126,136c0,0-56.3,58.3-86.9,48.5
		                c-30.5-9.8-56.3-37-59.2-85.7c-2.9-48.7-15.7-187.6-15.7-187.6l-61.6-44.4L370.2,546z"
                />
                <path
                    class="st1"
                    d="M278.7,638.3c0,0-27.2,300.6,25.8,310.7s-5.7-1.4-5.7-1.4s30.1,34.4,148.9-93.1l118.8-127.4L328.8,867.4
		                c5.7-7.2,30.1-250.5,30.1-250.5L278.7,638.3z"
                />
            </svg>
        </SvgIcon>
    );
}

export default Spark;

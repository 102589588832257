import { SvgIcon } from "@mui/material";

function Rpi(props) {
    let colours = props.colours || ["#37474F", "#64DD17", "#FF4081"];
    return (
        <SvgIcon {...props} viewBox="0 0 48 48">
            <path
                fill={colours[0]}
                d="M16.458,2.987c-0.212,0.007-0.441,0.086-0.701,0.29c-0.635-0.245-1.251-0.33-1.802,0.168c-0.852-0.109-1.128,0.118-1.337,0.383c-0.187-0.002-1.397-0.191-1.953,0.638C9.27,4.3,8.829,5.287,9.329,6.205c-0.285,0.441-0.58,0.877,0.086,1.719C9.179,8.394,9.325,8.9,9.88,9.516c-0.146,0.659,0.142,1.123,0.659,1.486c-0.097,0.9,0.826,1.424,1.102,1.61c0.106,0.526,0.326,1.021,1.38,1.295c0.174,0.784,0.808,0.917,1.421,1.083c-2.028,1.178-3.768,2.729-3.755,6.535l-0.297,0.529c-2.326,1.414-4.418,5.96-1.146,9.655c0.214,1.156,0.572,1.986,0.891,2.905c0.478,3.705,3.593,5.44,4.414,5.646c1.205,0.916,2.487,1.787,4.222,2.396c1.636,1.688,3.408,2.331,5.19,2.329c0.026,0,0.053,0.001,0.079,0c1.781,0.002,3.554-0.642,5.189-2.329c1.735-0.608,3.018-1.479,4.223-2.396c0.821-0.206,3.937-1.941,4.413-5.646c0.319-0.919,0.678-1.749,0.892-2.905c3.271-3.695,1.18-8.241-1.146-9.655l-0.297-0.53c0.012-3.805-1.729-5.356-3.756-6.534c0.613-0.166,1.247-0.3,1.421-1.084c1.055-0.272,1.275-0.769,1.381-1.295c0.276-0.186,1.198-0.709,1.103-1.611c0.517-0.361,0.805-0.826,0.657-1.484c0.557-0.615,0.702-1.124,0.466-1.592c0.667-0.842,0.371-1.277,0.087-1.719c0.499-0.918,0.059-1.905-1.337-1.739c-0.555-0.829-1.766-0.64-1.953-0.638c-0.209-0.265-0.486-0.492-1.337-0.383c-0.551-0.498-1.167-0.413-1.802-0.168c-0.756-0.596-1.256-0.119-1.826,0.062c-0.912-0.298-1.122,0.111-1.57,0.277c-0.997-0.211-1.299,0.247-1.777,0.731l-0.556-0.011c-1.503,0.886-2.249,2.69-2.514,3.616c-0.264-0.928-1.009-2.731-2.512-3.616l-0.556,0.011c-0.479-0.484-0.781-0.942-1.778-0.731c-0.448-0.166-0.657-0.575-1.571-0.277C17.208,3.22,16.863,2.975,16.458,2.987L16.458,2.987z"
            />
            <path
                fill={colours[1]}
                d="M13.466 6.885c3.987 2.055 6.305 3.718 7.575 5.134-.65 2.607-4.042 2.726-5.283 2.653.254-.119.467-.26.541-.479-.311-.221-1.415-.023-2.186-.456.296-.062.435-.12.573-.339-.727-.232-1.511-.433-1.973-.817.249.003.481.055.806-.17-.652-.351-1.348-.629-1.888-1.166.337-.009.701-.004.806-.129-.596-.37-1.1-.78-1.518-1.23.472.058.671.009.786-.075-.452-.461-1.023-.85-1.294-1.421.35.121.671.168.902-.011-.154-.345-.81-.55-1.189-1.357.369.036.761.081.839 0-.172-.697-.465-1.089-.753-1.496.79-.01 1.985.004 1.931-.063l-.488-.499c.771-.207 1.561.034 2.133.213.257-.203-.005-.459-.318-.721.655.087 1.247.238 1.782.445.286-.258-.186-.516-.413-.773 1.012.191 1.44.46 1.866.73.31-.295.018-.548-.19-.807.764.283 1.156.648 1.57 1.009.141-.19.357-.328.096-.784.542.312.95.68 1.252 1.092.335-.214.2-.506.201-.775.563.459.921.946 1.358 1.423.088-.064.165-.282.233-.626 1.344 1.303 3.242 4.586.488 5.889C19.367 9.343 16.568 7.938 13.466 6.885L13.466 6.885zM34.623 6.885c-3.986 2.055-6.305 3.718-7.574 5.134.65 2.607 4.043 2.726 5.283 2.653-.254-.119-.466-.26-.542-.479.312-.221 1.415-.023 2.186-.456-.296-.062-.434-.12-.573-.339.729-.232 1.514-.433 1.974-.817-.249.003-.481.055-.806-.17.652-.351 1.348-.629 1.889-1.166-.338-.009-.701-.004-.807-.129.598-.37 1.1-.78 1.518-1.23-.473.058-.671.009-.785-.075.451-.461 1.021-.85 1.293-1.421-.35.121-.67.168-.9-.011.152-.345.811-.55 1.188-1.357-.369.036-.76.081-.838 0 .172-.697.465-1.089.754-1.496-.789-.012-1.985.004-1.932-.063l.488-.499c-.771-.207-1.56.034-2.133.213-.258-.203.005-.459.318-.721-.654.087-1.248.237-1.782.445-.286-.258.186-.516.414-.774-1.013.191-1.44.461-1.867.731-.31-.295-.018-.548.19-.807-.763.283-1.156.648-1.57 1.008-.14-.189-.356-.327-.095-.783-.542.311-.951.68-1.252 1.092-.335-.215-.2-.506-.202-.775-.563.459-.92.946-1.358 1.423-.088-.064-.165-.282-.232-.626-1.345 1.303-3.243 4.586-.488 5.889C28.723 9.342 31.521 7.938 34.623 6.885L34.623 6.885z"
            />
            <g>
                <path
                    fill={colours[2]}
                    d="M28.873 33.426c.014 2.433-2.113 4.414-4.75 4.428-2.638.012-4.788-1.948-4.801-4.381 0-.016 0-.031 0-.047-.014-2.433 2.112-4.414 4.75-4.428 2.638-.012 4.787 1.948 4.801 4.382C28.873 33.395 28.873 33.411 28.873 33.426zM21.333 20.846c1.979 1.296 2.335 4.234.797 6.563-1.539 2.329-4.391 3.165-6.37 1.868l0 0c-1.979-1.297-2.335-4.235-.797-6.563C16.502 20.385 19.355 19.549 21.333 20.846L21.333 20.846zM26.676 20.61c-1.98 1.295-2.337 4.235-.798 6.563 1.539 2.33 4.391 3.166 6.369 1.869l0 0c1.979-1.297 2.337-4.234.798-6.564C31.506 20.15 28.654 19.314 26.676 20.61L26.676 20.61zM11.443 22.966c2.136-.573.721 8.838-1.017 8.066C8.514 29.493 7.898 24.988 11.443 22.966zM36.135 22.848c-2.138-.572-.722 8.839 1.016 8.066C39.064 29.375 39.68 24.871 36.135 22.848zM28.875 15.839c3.687-.624 6.756 1.567 6.632 5.565C35.385 22.938 27.516 16.065 28.875 15.839zM18.687 15.72c-3.687-.621-6.755 1.57-6.631 5.567C12.177 22.821 20.045 15.949 18.687 15.72zM23.983 14.789c-2.2-.058-4.313 1.634-4.318 2.613-.006 1.19 1.741 2.412 4.333 2.442 2.648.019 4.337-.977 4.347-2.205C28.354 16.246 25.937 14.767 23.983 14.789L23.983 14.789zM24.118 39.221c1.919-.084 4.493.619 4.499 1.549.031.905-2.336 2.947-4.626 2.907-2.373.103-4.699-1.943-4.668-2.651C19.287 39.984 22.212 39.174 24.118 39.221zM17.031 33.703c1.366 1.646 1.988 4.539.849 5.39-1.079.652-3.698.384-5.56-2.29-1.255-2.245-1.094-4.527-.212-5.199C13.426 30.801 15.463 31.884 17.031 33.703L17.031 33.703zM30.932 33.183c-1.479 1.731-2.301 4.888-1.223 5.905 1.03.791 3.799.681 5.842-2.156 1.484-1.906.988-5.087.141-5.934C34.431 30.026 32.623 31.271 30.932 33.183L30.932 33.183z"
                />
            </g>
        </SvgIcon>
    );
}

export default Rpi;

import { SvgIcon } from "@mui/material";

function Aws(props) {
    let colours = props.colours || ["#474A8A"];
    return (
        <SvgIcon {...props} viewBox="-10 0 520 532">
            <g id="Layer_2">
                <g>
                    <path
                        d="M103.462,207.061c15.821,0,26.365,2.92,31.637,8.761c5.267,5.841,6.522,15.867,3.766,30.074    c-2.879,14.795-8.423,25.355-16.641,31.682c-8.218,6.328-20.724,9.488-37.513,9.488h-25.33l15.549-80.005H103.462z M2,368.057    h41.643l9.877-50.823h35.669c15.739,0,28.686-1.65,38.85-4.96c10.165-3.305,19.402-8.848,27.717-16.63    c6.978-6.415,12.624-13.49,16.948-21.227c4.319-7.731,7.388-16.267,9.202-25.601c4.406-22.66,1.081-40.31-9.965-52.955    s-28.619-18.967-52.709-18.967H39.154L2,368.057z"
                        fill={colours[0]}
                    />
                    <path
                        d="M212.49,126.071h41.314l-9.878,50.823h36.806c23.157,0,39.132,4.042,47.924,12.117    c8.791,8.08,11.425,21.17,7.91,39.266l-17.286,88.957H277.31l16.436-84.582c1.87-9.622,1.184-16.185-2.064-19.684    c-3.248-3.5-10.159-5.251-20.729-5.251h-33.02L216.65,317.233h-41.315L212.49,126.071z"
                        fill={colours[0]}
                    />
                    <path
                        d="M428.49,207.061c15.821,0,26.365,2.92,31.637,8.761c5.269,5.841,6.523,15.867,3.766,30.074    c-2.879,14.795-8.421,25.355-16.641,31.682c-8.218,6.328-20.724,9.488-37.513,9.488H384.41l15.549-80.005H428.49z     M327.029,368.057h41.643l9.876-50.823h35.669c15.739,0,28.686-1.65,38.851-4.96c10.164-3.305,19.401-8.848,27.717-16.63    c6.979-6.415,12.624-13.49,16.948-21.227c4.318-7.731,7.388-16.267,9.201-25.601c4.406-22.66,1.082-40.31-9.965-52.955    c-11.046-12.645-28.619-18.967-52.709-18.967h-80.076L327.029,368.057z"
                        fill={colours[0]}
                    />
                </g>
            </g>
        </SvgIcon>
    );
}

export default Aws;

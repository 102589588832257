import { SvgIcon } from "@mui/material";

function Linux(props) {
    let colours = props.colours || ["#f5c500"];
    return (
        <SvgIcon {...props} viewBox="0 0 564.000000 564.000000">
            <g transform="translate(0.000000,564.000000) scale(0.100000,-0.100000)" fill={colours[0]} stroke="none">
                <path
                    d="M2720 5156 c-227 -42 -388 -165 -465 -354 -64 -158 -81 -323 -64
                        -645 14 -258 6 -362 -38 -502 -48 -151 -108 -255 -259 -445 -159 -201 -317
                        -463 -393 -655 -76 -191 -118 -401 -105 -531 l6 -60 -53 -69 c-101 -129 -125
                        -151 -192 -176 -90 -33 -169 -74 -194 -100 -34 -37 -63 -109 -63 -159 0 -25 7
                        -95 15 -156 15 -111 14 -112 -11 -195 -28 -96 -33 -189 -11 -240 34 -83 122
                        -121 347 -149 213 -26 269 -40 413 -104 167 -73 237 -90 372 -90 92 0 120 4
                        169 23 63 25 147 95 172 143 11 21 22 28 43 28 16 0 89 11 162 25 186 36 308
                        41 444 21 61 -10 156 -20 211 -23 l101 -6 27 -54 c62 -128 172 -203 305 -211
                        101 -6 175 11 275 61 65 34 105 65 206 163 139 135 179 163 360 254 155 77
                        203 111 236 160 53 79 27 180 -77 309 -64 79 -74 101 -100 212 -32 138 -77
                        213 -153 256 -14 7 -13 19 4 103 40 194 20 414 -57 620 -79 211 -195 402 -412
                        674 -286 358 -321 442 -321 765 0 231 -14 425 -41 556 -64 315 -224 496 -484
                        550 -88 18 -280 18 -375 1z m388 -655 c104 -54 167 -208 141 -344 -11 -58 -55
                        -160 -67 -155 -4 1 -31 12 -60 22 -56 21 -60 27 -38 46 8 6 20 35 27 64 21 96
                        -30 206 -95 206 -55 0 -105 -78 -106 -162 0 -27 -1 -48 -2 -48 -2 1 -28 9 -57
                        19 -66 23 -74 39 -62 125 15 104 69 189 146 229 42 23 127 21 173 -2z m-613
                        -37 c67 -31 125 -145 125 -243 0 -50 0 -50 -46 -71 -26 -11 -48 -20 -49 -20
                        -1 0 -5 25 -7 55 -6 69 -33 109 -76 113 -36 4 -56 -21 -67 -80 -8 -43 10 -132
                        31 -149 21 -17 17 -35 -10 -53 -36 -23 -50 -13 -82 57 -65 146 -33 325 70 388
                        38 23 68 24 111 3z m315 -336 c19 -6 71 -29 115 -53 44 -23 123 -59 176 -79
                        99 -37 130 -60 148 -109 29 -76 -54 -183 -179 -231 -25 -10 -88 -41 -140 -71
                        -123 -68 -156 -79 -244 -73 -83 6 -111 18 -189 87 -32 28 -82 67 -111 85 -29
                        19 -63 47 -74 64 -61 86 -51 129 51 204 39 29 93 72 120 95 104 93 203 117
                        327 81z m517 -608 c70 -211 168 -446 261 -629 40 -80 91 -187 113 -238 39 -92
                        109 -318 109 -353 0 -12 10 -20 31 -24 68 -13 76 -10 88 40 55 219 -25 489
                        -205 695 -35 40 -64 76 -64 81 0 35 72 -34 165 -157 67 -89 141 -246 171 -360
                        22 -88 25 -115 21 -221 l-4 -121 46 -24 c114 -59 192 -121 220 -176 6 -12 11
                        -44 11 -72 0 -44 -3 -51 -20 -51 -16 0 -20 7 -20 35 0 20 -7 49 -15 64 -30 60
                        -180 146 -307 177 -127 31 -204 -2 -230 -100 -10 -38 -20 -51 -54 -71 -114
                        -67 -165 -195 -184 -468 -12 -166 -20 -216 -52 -324 -20 -70 -23 -74 -82 -112
                        -217 -142 -509 -198 -731 -142 -82 21 -260 103 -268 124 -2 7 -32 49 -65 93
                        l-60 82 40 6 c55 9 76 21 98 59 29 47 25 95 -14 173 -60 120 -175 229 -388
                        367 -147 97 -214 190 -245 342 -20 102 -8 213 41 358 48 144 203 447 228 447
                        10 0 -2 -29 -85 -200 -141 -289 -160 -490 -65 -680 l33 -64 8 69 c26 228 73
                        380 202 660 169 368 247 588 276 785 l11 80 37 -22 c20 -12 65 -46 101 -76 91
                        -77 137 -96 231 -96 81 0 94 6 285 107 33 17 88 45 121 60 33 15 77 46 99 68
                        l39 40 9 -33 c5 -18 33 -107 63 -198z m-1680 -1580 c81 -36 130 -100 257 -330
                        89 -164 141 -244 213 -335 171 -215 233 -325 233 -414 0 -72 -28 -140 -79
                        -191 -64 -64 -126 -85 -251 -84 -117 0 -211 25 -359 94 -125 58 -225 83 -416
                        105 -183 20 -234 35 -274 80 -26 30 -31 44 -31 89 0 29 11 90 25 136 30 99 30
                        111 9 255 -14 91 -14 118 -4 155 18 67 69 110 176 148 105 38 143 66 217 164
                        64 85 102 123 137 137 40 16 102 12 147 -9z m2046 -25 c14 -98 86 -192 167
                        -216 73 -22 182 15 293 100 60 45 74 51 116 51 133 0 188 -57 230 -236 28
                        -121 40 -147 101 -223 64 -79 100 -150 100 -195 0 -59 -43 -96 -213 -182 -203
                        -102 -264 -144 -383 -266 -60 -62 -127 -118 -165 -141 -219 -129 -429 -91
                        -526 96 -23 45 -27 63 -26 132 1 81 13 140 79 368 25 88 38 158 45 252 20 238
                        36 335 69 402 30 60 69 103 95 103 7 0 15 -19 18 -45z"
                />
                <path
                    d="M2574 4056 c-15 -11 -16 -17 -7 -27 9 -9 20 -8 47 5 20 9 36 21 36
                        26 0 15 -54 12 -76 -4z"
                />
                <path
                    d="M2760 4055 c0 -8 7 -15 15 -15 8 0 24 -6 35 -12 15 -10 22 -10 32 0
                        11 11 9 16 -6 28 -27 19 -76 19 -76 -1z"
                />
                <path
                    d="M3109 3884 c-7 -9 -38 -35 -69 -58 -132 -101 -284 -162 -385 -153
                    -85 6 -174 54 -252 133 -35 35 -65 64 -68 64 -3 0 -5 -10 -5 -22 0 -31 97
                    -119 173 -157 133 -68 239 -63 405 19 131 64 242 149 242 184 0 12 -28 5 -41
                    -10z"
                />
            </g>
        </SvgIcon>
    );
}

export default Linux;
